//
// Forms.scss
//


// checkbox input right

.form-check-right{
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;;
  .form-check-input{
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }
  .form-check-label{
    display: block;
  }
}

.form-check{
  position: relative;
  text-align: left /*rtl: right*/;
}


.form-check-label{
  cursor: pointer;
  margin-bottom: 0;
}


.form-check-col{
  
  .form-floating{
    display: flex;
    gap: .8rem;

    label{
      padding: 0;
      position: static;
      pointer-events: all;
    }
  }
}

.form-date {
  width: 100%;
  padding: .75rem .625rem .75rem;
  height: calc(3.5rem + calc(var(--bs-border-width)* 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width)* 2));
  line-height: 1.25;
  border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker {
  display: flex;
}

.form-control {

  overflow: hidden;

  .react-datepicker-wrapper {
    height: 100%;
    width: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
  }

  .input-no-border {
    border: none;
    outline: green solid 1px;
    height: 100%;
    width: 100%;
    padding: 26px 12px 10px
  }
}


.old-data{
  background-color: #eee !important;
}

.boost-z-sm {
  z-index: 3
}

.boost-z-lg {
  z-index: 4
}

.react-datepicker__header {

  select,
  button {
    border: none;
    background-color: transparent;
  }

  button:nth-of-type(1) {
    margin-right: 2rem;
  }

  button:nth-of-type(2) {
    margin-left: 2rem;
  }
}

.react-datepicker__day--selected {
  background-color: #5664d2;
}

.time-picker__input {
  position: relative;
}

.time-picker__input {
  position: relative;
}

.time-picker__container {
  background-color: #fff;
  border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  border-radius: var(--bs-border-radius);
  padding: 5px 10px;

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    top: -5px;
    left: 30px;
    background-color: #fff;
    border: var(--bs-border-width) solid var(--bs-border-color-translucent);
    border-right: none;
    border-bottom: none;
  }

  .time-picker__values {
    display: flex;
    text-align: center;

    p {
      flex: 0 0 33%;
      margin-bottom: 0;

      &:first-child {
        position: relative;

        &::after {
          content: ":";
          position: absolute;
          right: 0;
        }
      }
    }
  }

  button {
    width: 40px;
    border: none;
    background-color: transparent;
  }
}

.time-picker__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: red;
  opacity: .3;
}

.popup-content {
  z-index: 99999!important
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  &::after, &::before{
    left: -20px;
  }
}

.show__pass{
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 2;
  border: none;
  background-color: transparent;
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(.85);
  transition: all 0.2s ease-in;

  &:hover{
    color: rgba(var(--bs-body-color-rgb));;
  }
}